export const localeCodes = ["de","en","it"]

export const localeMessages = {
}

export const additionalMessages = Object({"en":[],"it":[],})

export const resolveNuxtI18nOptions = async (context) => {
  const nuxtI18nOptions = Object({})
  const vueI18nOptionsLoader = async (context) => Object({"legacy":false,"locale":"de","messages": Object({"en":{
  "firstname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Firstname"])},
  "lastname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lastname"])},
  "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
  "startMeeting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start meeting"])},
  "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Required field"])},
  "waitingUntilStart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The meeting is about to start..."])},
  "waitUntilModeratorApproves": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waiting for a moderator to approve your participation..."])},
  "wrongPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wrong room password"])},
  "meetingNotOpenYet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meeting not open yet"])},
  "meetingWillOpen": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Meeting will open on ", _interpolate(_named("date"))])},
  "meetingClosed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meeting closed"])},
  "keepMeetingOpen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keep meeting open"])},
  "meetingClosedText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The meeting is already closed."])},
  "meetingLeft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meeting left"])},
  "meetingLeftText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You left the meeting. Thank you for your time."])},
  "moderatorSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moderator Settings"])},
  "minutesTillClosing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minutes until aut. closing"])},
  "allowScreenshare": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Screenshare allowed"])},
  "muteParticipants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mute participants"])},
  "participantInWaitingRoom": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("name")), " wants to join the meeting"])},
  "participantInWaitingRoomDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Approve or reject the participant"])},
  "permitEntry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permit entry"])},
  "denyEntry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deny entry"])},
  "dsgvo": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["I agree to the <a href=\"", _interpolate(_named("link")), "\" target=\"_blank\" rel=\"noopener\">privacy policy</a>."])},
  "mute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Micro off"])},
  "unmute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Micro on"])},
  "videoOff": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Camera off"])},
  "videoOn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Camera on"])},
  "disconnect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disconnect"])},
  "finishMeeting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finish meeting"])},
  "finishMeetingDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to end the meeting for everyone?"])},
  "shareScreen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Share"])},
  "stopShareScreen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stop Share"])},
  "more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More"])},
  "participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["participants"])},
  "you": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(You)"])},
  "background": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Background"])},
  "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settings"])},
  "members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Participants"])},
  "camera": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Camera"])},
  "audio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Audio"])},
  "chooseBackground": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose backgorund"])},
  "validationError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validation error"])},
  "permissionDeniedTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Camera and Microphone Access Required"])},
  "permissionDeniedMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To participate in the consultation with image and sound, please allow access to your camera and microphone. Change this in the browser settings and reload the page."])},
  "redirectErrorTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redirect Error"])},
  "redirectErrorMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error occurred while redirecting. Please try again."])},
  "allowCameraAndMic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allow camera and microphone access"])},
  "allowCameraAndMicDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please allow access to the camera and microphone by clicking the button below.<br>This is required for participation in the consultation."])},
  "cameraAndMicGranted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Camera and microphone access has been successfully granted. You can now proceed."])},
  "cameraAndMicDenied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Access to the camera and microphone was denied. Please allow access via browser settings to proceed."])},
  "accessGranted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Access granted"])},
  "accessDenied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Access denied"])},
  "endPrivacyMode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exit privacy mode"])},
  "banUserDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to ban this user? The user will not be able to rejoin."])},
  "banUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ban"])},
  "systemRequirementsTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unsupported device or browser"])},
  "systemRequirementsDescription": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["\n                        <p>It looks like your current device or browser is not supported by our meeting platform.\n                        To join our meeting, please try one of the following browsers:</p>\n                        <br>\n                        <ul>\n                            <li>Google Chrome (Version 93 or higher)</li>\n                            <li>Mozilla Firefox (Version 91 or higher)</li>\n                            <li>Microsoft Edge (Version 94 or higher)</li>\n                            <li>Safari (Version 15 or higher)</li>\n                        </ul>\n                        <br>\n                        <p>If you continue to have problems, please contact our support at ", _interpolate(_named("link")), "</p>\n                        <br>\n                        <p>Thank you for your understanding and patience!</p>"])},
  "activeMediaErrorTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error occurred"])},
  "activeMediaErrorMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error occurred while activating the media. Please refresh the page and try again."])}
},"de":{
  "firstname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorname"])},
  "lastname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nachname"])},
  "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort"])},
  "startMeeting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meeting starten"])},
  "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pflichtfeld"])},
  "waitingUntilStart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Meeting startet gleich..."])},
  "waitUntilModeratorApproves": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Warten bis ein Moderator die Teilnahme genehmigt..."])},
  "wrongPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Falsches Passwort"])},
  "meetingNotOpenYet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meeting ist noch geschlossen"])},
  "meetingWillOpen": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Das Meeting öffnet am ", _interpolate(_named("date"))])},
  "meetingClosed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meeting geschlossen"])},
  "keepMeetingOpen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meeting offenhalten"])},
  "meetingClosedText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Meeting ist bereits geschlossen."])},
  "meetingLeft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meeting verlassen"])},
  "meetingLeftText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie haben das Meeting verlassen. Vielen Dank für Ihre Teilnahme."])},
  "moderatorSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moderator Einstellungen"])},
  "minutesTillClosing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minuten bis aut. Schließung"])},
  "allowScreenshare": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Screenshare zulassen"])},
  "muteParticipants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teilnehmer stummschalten"])},
  "participantInWaitingRoom": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("name")), " möchte dem Meeting beitreten"])},
  "participantInWaitingRoomDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teilnehmer genehmigen oder ablehnen"])},
  "permitEntry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zulassen"])},
  "denyEntry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ablehnen"])},
  "dsgvo": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ich erkläre mich mit den <a href=\"", _interpolate(_named("link")), "\" target=\"_blank\" rel=\"noopener\">Datenschutzbestimmungen</a> einverstanden."])},
  "mute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Micro aus"])},
  "unmute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Micro ein"])},
  "videoOff": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kamera aus"])},
  "videoOn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kamera ein"])},
  "disconnect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meeting verlassen"])},
  "finishMeeting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meeting beenden"])},
  "finishMeetingDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sind Sie sicher, dass Sie das Meeting für alle beenden möchten?"])},
  "shareScreen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teilen"])},
  "stopShareScreen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teilen beenden"])},
  "more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mehr"])},
  "participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teilnehmer"])},
  "you": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(Du)"])},
  "background": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hintergrund"])},
  "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einstellungen"])},
  "members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teilnehmer"])},
  "camera": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kamera"])},
  "audio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Audio"])},
  "chooseBackground": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hintergrund auswählen"])},
  "validationError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validierungsfehler"])},
  "permissionDeniedTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zugriff auf Kamera und Mikrofon benötigt"])},
  "permissionDeniedMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Um an der Beratung mit Bild und Ton teilnehmen zu können, erlauben Sie bitte den Zugriff auf Kamera und Mikrofon. Ändern Sie dies in den Browsereinstellungen und laden Sie die Seite neu."])},
  "redirectErrorTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehler beim Weiterleiten"])},
  "redirectErrorMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es ist ein Fehler beim Weiterleiten aufgetreten. Bitte versuchen Sie es erneut."])},
  "allowCameraAndMic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kamera- und Mikrofonzugriff erlauben"])},
  "allowCameraAndMicDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte gestatten Sie den Zugriff auf Kamera und Mikrofon durch Klick auf den nachfolgenden Button.<br>Dies ist für eine Teilnahme an der Beratung erforderlich."])},
  "cameraAndMicGranted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kamera- und Mikrofonzugriff wurde erfolgreich erteilt. Sie können jetzt fortfahren."])},
  "cameraAndMicDenied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Zugriff auf Kamera und Mikrofon wurde verweigert. Bitte erlauben Sie den Zugriff über die Browsereinstellungen, um fortzufahren."])},
  "accessGranted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zugriff erlaubt"])},
  "accessDenied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zugriff verweigert"])},
  "endPrivacyMode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datenschutzmodus beenden"])},
  "banUserDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sind Sie sicher, dass Sie diesen Benutzer sperren möchten? Der Benutzer kann nicht wieder beitreten."])},
  "banUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bannen"])},
  "systemRequirementsTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nicht unterstütztes Gerät oder Browser"])},
  "systemRequirementsDescription": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["\n                        <p>Es sieht so aus, als ob Ihr aktuelles Gerät oder Browser nicht von unserer Meeting-Plattform unterstützt wird.\n                        Um an unserem Meeting teilzunehmen, probieren Sie bitte einen der folgenden Browser aus:</p>\n                        <br>\n                        <ul>\n                            <li>Google Chrome (Version 93 oder höher)</li>\n                            <li>Mozilla Firefox (Version 91 oder höher)</li>\n                            <li>Microsoft Edge (Version 94 oder höher)</li>\n                            <li>Safari (Version 15 oder höher)</li>\n                        </ul>\n                        <br>\n                        <p>Falls Sie weiterhin Probleme haben, kontaktieren Sie bitte unseren Support unter ", _interpolate(_named("link")), "</p>\n                        <br>\n                        <p>Vielen Dank für Ihr Verständnis und Ihre Geduld!</p>\n                    "])},
  "activeMediaErrorTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ein Fehler ist aufgetreten"])},
  "activeMediaErrorMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ein Fehler ist beim Aktivieren der Kamera aufgetreten. Bitte aktualisieren Sie die Seite und versuchen Sie es erneut."])}
},"it":{
  "firstname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome"])},
  "lastname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cognome"])},
  "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
  "startMeeting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inizia la riunione"])},
  "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Campo obbligatorio"])},
  "waitingUntilStart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La riunione sta per iniziare..."])},
  "waitUntilModeratorApproves": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In attesa dell'approvazione del moderatore per la tua partecipazione..."])},
  "wrongPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password della stanza errata"])},
  "meetingNotOpenYet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Riunione non ancora aperta"])},
  "meetingWillOpen": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["La riunione si aprirà il ", _interpolate(_named("date"))])},
  "meetingClosed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Riunione chiusa"])},
  "keepMeetingOpen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mantieni la riunione aperta"])},
  "meetingClosedText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La riunione è già terminata."])},
  "meetingLeft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Riunione abbandonata"])},
  "meetingLeftText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hai lasciato la riunione. Grazie per il tuo tempo."])},
  "moderatorSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impostazioni del moderatore"])},
  "minutesTillClosing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minuti alla chiusura aut."])},
  "allowScreenshare": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Condivisione schermo consentita"])},
  "muteParticipants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Silenzia i partecipanti"])},
  "participantInWaitingRoom": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("name")), " vuole unirsi alla riunione"])},
  "participantInWaitingRoomDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Approva o rifiuta il partecipante"])},
  "permitEntry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permetti l'ingresso"])},
  "denyEntry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nega l'ingresso"])},
  "dsgvo": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Accetto la <a href=\"", _interpolate(_named("link")), "\" target=\"_blank\" rel=\"noopener\">politica sulla privacy</a>."])},
  "mute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Micro off"])},
  "unmute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Micro su"])},
  "videoOff": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ferma Video"])},
  "videoOn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avvia Video"])},
  "disconnect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disconnetti"])},
  "finishMeeting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fine riunione"])},
  "finishMeetingDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sei sicuro di voler terminare la riunione per tutti?"])},
  "shareScreen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Condividi Schermo"])},
  "stopShareScreen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ferma Condivisione"])},
  "more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Altro"])},
  "participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["partecipanti"])},
  "you": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(Tu)"])},
  "background": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sfondo"])},
  "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impostazioni"])},
  "members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partecipanti"])},
  "camera": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telecamera"])},
  "audio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Audio"])},
  "chooseBackground": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scegli lo sfondo"])},
  "validationError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Errore di validazione"])},
  "permissionDeniedTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accesso a fotocamera e microfono richiesto"])},
  "permissionDeniedMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per partecipare alla consultazione con immagini e suoni, si prega di consentire l'accesso alla fotocamera e al microfono. Modificare questa impostazione nelle impostazioni del browser e ricaricare la pagina."])},
  "redirectErrorTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Errore di reindirizzamento"])},
  "redirectErrorMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si è verificato un errore durante il reindirizzamento. Si prega di riprovare."])},
  "allowCameraAndMic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consenti l'accesso alla fotocamera e al microfono"])},
  "allowCameraAndMicDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si prega di consentire l'accesso alla fotocamera e al microfono cliccando il pulsante sottostante.<br>Questo è necessario per partecipare alla consultazione."])},
  "cameraAndMicGranted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'accesso alla fotocamera e al microfono è stato concesso con successo. Ora puoi procedere."])},
  "cameraAndMicDenied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'accesso alla fotocamera e al microfono è stato negato. Si prega di consentire l'accesso tramite le impostazioni del browser per procedere."])},
  "accessGranted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accesso consentito"])},
  "accessDenied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accesso negato"])},
  "endPrivacyMode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esci dalla modalità privacy"])},
  "banUserDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sei sicuro di voler bannare questo utente? L'utente non potrà più partecipare."])},
  "banUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminare"])},
  "systemRequirementsTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dispositivo o browser non supportato"])},
  "systemRequirementsDescription": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["\n                        <p>Sembra che il tuo dispositivo o browser attuale non sia supportato dalla nostra piattaforma di meeting.\n                        Per partecipare al nostro meeting, prova uno dei seguenti browser:</p>\n                        <br>\n                        <ul>\n                            <li>Google Chrome (Versione 93 o superiore)</li>\n                            <li>Mozilla Firefox (Versione 91 o superiore)</li>\n                            <li>Microsoft Edge (Versione 94 o superiore)</li>\n                            <li>Safari (Versione 15 o superiore)</li>\n                        </ul>\n                        <br>\n                        <p>Se continui ad avere problemi, contatta il nostro supporto all'indirizzo ", _interpolate(_named("link")), "</p>\n                        <br>\n                        <p>Grazie per la tua comprensione e pazienza!</p>"])},
  "activeMediaErrorTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si è verificato un errore"])},
  "activeMediaErrorMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si è verificato un errore durante l'attivazione dei media. Si prega di aggiornare la pagina e riprovare."])}
},}),})
  nuxtI18nOptions.vueI18n = await vueI18nOptionsLoader(context)
  nuxtI18nOptions.locales = ["de","en","it"]
  nuxtI18nOptions.defaultLocale = "de"
  nuxtI18nOptions.defaultDirection = "ltr"
  nuxtI18nOptions.routesNameSeparator = "___"
  nuxtI18nOptions.trailingSlash = false
  nuxtI18nOptions.defaultLocaleRouteNameSuffix = "default"
  nuxtI18nOptions.strategy = "no_prefix"
  nuxtI18nOptions.lazy = false
  nuxtI18nOptions.langDir = null
  nuxtI18nOptions.rootRedirect = null
  nuxtI18nOptions.detectBrowserLanguage = false
  nuxtI18nOptions.differentDomains = false
  nuxtI18nOptions.baseUrl = ""
  nuxtI18nOptions.dynamicRouteParams = false
  nuxtI18nOptions.customRoutes = "page"
  nuxtI18nOptions.pages = Object({})
  nuxtI18nOptions.skipSettingLocaleOnNavigate = false
  nuxtI18nOptions.onBeforeLanguageSwitch = (() => "")
  nuxtI18nOptions.onLanguageSwitched = (() => null)
  nuxtI18nOptions.types = undefined
  nuxtI18nOptions.debug = false
  return nuxtI18nOptions
}

export const nuxtI18nOptionsDefault = Object({vueI18n: undefined,locales: [],defaultLocale: "",defaultDirection: "ltr",routesNameSeparator: "___",trailingSlash: false,defaultLocaleRouteNameSuffix: "default",strategy: "prefix_except_default",lazy: false,langDir: null,rootRedirect: null,detectBrowserLanguage: Object({"alwaysRedirect":false,"cookieCrossOrigin":false,"cookieDomain":null,"cookieKey":"i18n_redirected","cookieSecure":false,"fallbackLocale":"","redirectOn":"root","useCookie":true}),differentDomains: false,baseUrl: "",dynamicRouteParams: false,customRoutes: "page",pages: Object({}),skipSettingLocaleOnNavigate: false,onBeforeLanguageSwitch: (() => ""),onLanguageSwitched: (() => null),types: undefined,debug: false})

export const nuxtI18nInternalOptions = Object({__normalizedLocales: [Object({"code":"de","iso":"de"}),Object({"code":"en","iso":"en"}),Object({"code":"it","iso":"it"})]})
export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const isSSG = false
export const isSSR = true
