import { default as errorAgWXU9CPFRMeta } from "/opt/build/repo/pages/error.vue?macro=true";
import { default as _91key_937kuWI2A8NRMeta } from "/opt/build/repo/pages/group/[key].vue?macro=true";
import { default as indexlaFeZuYZkUMeta } from "/opt/build/repo/pages/index.vue?macro=true";
import { default as _91role_93Ki9I0pUU0wMeta } from "/opt/build/repo/pages/rooms/[key]/[role].vue?macro=true";
import { default as testsQ0rJvZrjqMeta } from "/opt/build/repo/pages/test.vue?macro=true";
export default [
  {
    name: "error",
    path: "/error",
    component: () => import("/opt/build/repo/pages/error.vue").then(m => m.default || m)
  },
  {
    name: "group-key",
    path: "/group/:key()",
    component: () => import("/opt/build/repo/pages/group/[key].vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/opt/build/repo/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "rooms-key-role",
    path: "/rooms/:key()/:role()",
    component: () => import("/opt/build/repo/pages/rooms/[key]/[role].vue").then(m => m.default || m)
  },
  {
    name: "test",
    path: "/test",
    component: () => import("/opt/build/repo/pages/test.vue").then(m => m.default || m)
  }
]